import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
	providedIn: 'root'
})
export class VoicePresetApiService {

	constructor(private http: HttpClient) {

	}

	getPresets(): Observable<string[]> {
		return this.http.get<string[]>("/api/getpresets");
	}
}
