import { BrowserModule } from "@angular/platform-browser";
import { NgModule, enableProdMode } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { HttpClientModule } from '@angular/common/http';
import { Ng5SliderModule } from 'ng5-slider';

import { AppComponent } from "./app.component";
import { PresetBuilderComponent } from './components/preset-builder/preset-builder.component';
import { TextToSpeechComponent } from './components/text-to-speech/text-to-speech.component';
import { ProfileImagePipe } from './pipes/profile-image.pipe';
import { VoiceSelectorComponent } from './components/text-to-speech/voice-selector/voice-selector.component';

@NgModule({
	declarations: [AppComponent, PresetBuilderComponent, TextToSpeechComponent, ProfileImagePipe, VoiceSelectorComponent],
	imports: [BrowserModule, FormsModule, HttpClientModule, Ng5SliderModule],
	providers: [],
	bootstrap: [AppComponent]
})


export class AppModule {
}

enableProdMode();
