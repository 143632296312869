import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
	providedIn: 'root'
})
export class TextToSpeechApiService {

	constructor(private http: HttpClient) {

	}

	getAudio(text: string, preset: string, speed: number): Observable<Blob> {
		const newUrl = ("/api/synthesizepreset?text=" + text +
						"&preset=" + preset + "&speed=" + speed);
		return this.http.get(newUrl, {
			responseType: "blob"
		});
	}
}
