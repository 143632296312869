import { Component, OnInit, Input, EventEmitter, Output } from "@angular/core";
import { VoicePresetApiService } from 'src/app/services/voice-preset-api.service';

@Component({
	selector: "app-voice-selector",
	templateUrl: "./voice-selector.component.html",
	styleUrls: ["./voice-selector.component.scss"]
})
export class VoiceSelectorComponent implements OnInit {
	voices: string[];
	isLoading = true;

	@Output() changeVoice: EventEmitter<string> = new EventEmitter<string>();
	@Input() currentVoice: string = "";

	constructor(private apiClient: VoicePresetApiService) {
		this.isLoading = true;
		apiClient.getPresets().subscribe(presets => {
			this.voices = presets;
			this.isLoading = false;
		}, error => {
			alert("Could not load voices, please refresh and try again!");
		});
	}

	ngOnInit() {

	}
}
