import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { enableTransliteration } from "transliteration-input-tools/dist/transliteration-input.bundle.js";
import { TextToSpeechApiService } from 'src/app/services/text-to-speech-api.service';
import { Options, LabelType } from 'ng5-slider';

@Component({
	selector: 'app-text-to-speech',
	templateUrl: './text-to-speech.component.html',
	styleUrls: ['./text-to-speech.component.scss']
})

export class TextToSpeechComponent implements OnInit {

	constructor(private tts: TextToSpeechApiService) { }

	isLoading: boolean = false;
	isDownloading: boolean = false;

	sliderValue: number = 0;
	sliderOptions: Options = {
		floor: -100,
		ceil: 100,
		showSelectionBarFromValue: 0,
		translate: (value: number, label: LabelType): string => {
			if (value === 0) {
				return "Normal Speed";
			}

			if (value < 0) {
				return (0 - value) + "% Slower";
			} else {
				return value + "% Faster";
			}
		}
	};


	currentVoice: string = "Ashma";
	@ViewChild("nepaliTransliteration") nepaliTransliteration: ElementRef;
	@ViewChild("audioPlayer") audioPlayer: ElementRef;

	changeVoice(preset: string) {
		this.currentVoice = preset;
		this.sliderValue = 0;
	}

	play(text: HTMLInputElement) {
		if (text.value.length <= 0) {
			alert("Text cannot be empty.");
		} else {
			this.isLoading = true;
			this.tts.getAudio(text.value, this.currentVoice, this.sliderValue).subscribe(blob => {
				const blobUrl = URL.createObjectURL(blob);
				this.audioPlayer.nativeElement.src = blobUrl;
				this.audioPlayer.nativeElement.play();
				this.isLoading = false;
			}, error => {
				alert("Sorry the request could not be completed because of: " + error.statusText);
				this.isLoading = false;
			});
		}
	}

	download(text: HTMLInputElement) {
		if (text.value.length <= 0) {
			alert("Text cannot be empty.");
		} else {
			this.isDownloading = true;
			this.tts.getAudio(text.value, this.currentVoice, this.sliderValue).subscribe(blob => {

				let fileName = text.value;
				if (text.value.length > 10) {
					fileName = text.value.substring(0, 10);
				}
				fileName += "-" + this.currentVoice + ".wav";
				this.saveFileOnUserDevice({
					content: blob,
					name: fileName
				});
				this.isDownloading = false;
			}, error => {
				alert("Sorry the request could not be completed because of: " + error.statusText);
				this.isDownloading = false;
			});
		}
	}

	saveFileOnUserDevice(file) { // content: blob, name: string
		if (navigator.msSaveBlob) { // For ie and Edge
			return navigator.msSaveBlob(file.content, file.name);
		} else {
			const link = document.createElement('a');
			link.href = window.URL.createObjectURL(file.content);
			link.download = file.name;
			document.body.appendChild(link);
			link.dispatchEvent(new MouseEvent('click', { bubbles: true, cancelable: true, view: window }));
			link.remove();
			window.URL.revokeObjectURL(link.href);
		}
	}


	ngOnInit() {
		enableTransliteration(this.nepaliTransliteration.nativeElement, "ne");
	}

}
