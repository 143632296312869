import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
	name: "profileImage"
})
export class ProfileImagePipe implements PipeTransform {
	transform(name: string): any {
		// const number = Math.floor(Math.random() * (99 - 0) + 0);
		// return "https://randomuser.me/api/portraits/" + type + "/" + number + ".jpg";
		return "assets/avatars/" + name.toLocaleLowerCase().replace(" ", "") + ".svg";
	}
}
