import { Component, OnInit, ViewChild, ElementRef } from "@angular/core";
import { enableTransliteration } from "transliteration-input-tools/dist/transliteration-input.bundle.js";

@Component({
	selector: "app-preset-builder",
	templateUrl: "./preset-builder.component.html",
	styleUrls: ["./preset-builder.component.scss"]
})
export class PresetBuilderComponent implements OnInit {
	defaultAudioUrl = "";

	// Settings
	language: string = "mar_aup";
	speed: string = "100";
	pitch: string = "127";
	presetName: string;

	@ViewChild("audio") audio: ElementRef;
	@ViewChild("nepaliTransliteration") nepaliTransliteration: ElementRef;

	convert(text: HTMLInputElement) {
		const newUrl = ("https://localhost:5001/api/synthesize?text=" + text.value +
						"&language=" + this.language +
						"&speed=" + this.speed +
						"&pitch=" + this.pitch);
		this.audio.nativeElement.src = newUrl;
		this.audio.nativeElement.play();
	}

	ngOnInit() {
		// Enable transliteration on the input
		this.audio.nativeElement.src = this.defaultAudioUrl;
		enableTransliteration(this.nepaliTransliteration.nativeElement, "ne");
	}
}
